var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"w-100",attrs:{"dense":"","headers":_vm.headers,"items":_vm.customers,"item-key":"item.uuid","search":_vm.search},scopedSlots:_vm._u([{key:"header.firstName",fn:function(){return [_vm._v(_vm._s(_vm.$t('firstName')))]},proxy:true},{key:"header.surname",fn:function(){return [_vm._v(_vm._s(_vm.$t('surname')))]},proxy:true},{key:"header.msisdn",fn:function(){return [_vm._v(_vm._s(_vm.$t('telephone')))]},proxy:true},{key:"header.identity_number",fn:function(){return [_vm._v(_vm._s(_vm.$t('idNumber')))]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","to":{name: 'ShowCustomer', query: { uuid: item.uuid } }}},[_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v("mdi-account")])],1)]}},{key:"item.msisdn",fn:function(ref){
var item = ref.item;
return [_c('Telephone',{attrs:{"msisdn":item.msisdn}})]}},{key:"item.isAFarmer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.uzimaSubcounty)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"dense":"","color":"primary","text":"","to":{name: 'VaOrder', query: { uuid: item.uuid } }}},[_vm._v(_vm._s(_vm.doc[item.id] || 0))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }