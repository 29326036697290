<template>
  <v-stepper-content step="7" class="ml-0">
    <v-form v-model="valid" ref="form7">
      <v-container fluid>
        <NileFarmerNeeds v-model="formFields" />
        <v-row class="mt-4">
          <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="next" :disabled="!valid">Save & Continue</v-btn>
          <v-btn color="gray darken-3" class="mb-2" @click="previous">Back</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import NileFarmerNeeds from "@/views/inputOrders/NileUg";

export default {
  name: "FarmersNeeds",
  components: {
    NileFarmerNeeds
  },
  props: ["rules", "value", "next", "previous"],
  data(){
    return {
      valid: true,
      formFields: {},
    }
  },
  computed: {
    currency() {
      return this.$store.getters.currency;
    },
  },
   watch: {
    value: {
      immediate: true,
      handler(to) { this.formFields = to; }
    },
    formFields(to) { this.$emit('input', to); },
  },
  methods:{
    validate() {
      this.$refs.form7.validate();
    },
    validateAndContinue(){
    //   this.validate();
      this.next();
    }
  }
};
</script>

<style>
</style>