<template>
  <IndexCompact :customers="customers" :moc="moc" :doc="doc" v-if="breakpoint=='xs'" />
  <IndexNormal :customers="customers" :moc="moc" :doc="doc" v-else />
</template>


<script>
  import lookups from '@/mixins/lookups';
  import displaySwitches from '@/mixins/displaySwitches';

  import IndexCompact from '@/views/customers/partners/uzima_ug/IndexCompact';
  import IndexNormal from '@/views/customers/partners/uzima_ug/IndexNormal';

  export default {
    components: { IndexCompact, IndexNormal },
    // props: ['customers'],
    data: () => {
      return {
        doc: {}
      }
    },
    computed: {
      breakpoint() { return this.$vuetify.breakpoint.name; },
      moc() {
        let date = new Date();
        let start = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0]
        let r = {};
        this.$store.getters.deliveries.filter(e => (e.createdAt > start)).forEach( e => r[e.deliveredToUuid] = (r[e.deliveredToUuid] || 0) + Number(e.quantity) );
        return r;
      },
      customers() { return this.$store.getters.customers; },
    },
    mounted() {
      this.remoteLookup('uzimaUgInputOrderBalances', {}).then(e => {this.doc = e; console.log("results: ", e); console.log("reports: ", this.$store.getters.landingPageCache)}).catch(e => console.log('error: ', e));
    },

    mixins: [lookups, displaySwitches],
  }
</script>


<i18n>
{
  "en": {
  },
  "es": {
  },
  "sw": {
  }
}
</i18n>
