<template>
  <PageHeader :title="field.name || 'New Field'" :subtitle="customer.name" icon="mdi-flower-outline" @subtitle="toField" @back="back" @save="upsert" back save :valid='formValid' v-if="field" >

    <v-form v-model="formValid">

      <v-row no-gutters>

        <v-col cols="12" class="pa-1">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <Row :title="$t('fieldName')" :text="field.name" v-if="field.name" />

                <Row :title="$t('crop')">
                  <v-select :items="crops" class="text-left" v-model="field.cropId" :rules="cropRules"></v-select>
                </Row>

                <Row :title="$t('size')" >
                  <LandSizeInput v-model="field.fieldSize" @input="updateFieldSize" />
                </Row>

                <Row :title="$t('plants')" ><v-text-field v-model="field.numberOfPlants" type="number" :rules="numberRules" min=0 ></v-text-field></Row>

                <Row :title="$t('location')" ><LocationInput v-model="field.location" /></Row>

              </tbody>
            </template>
          </v-simple-table>
        </v-col>

      </v-row>

    </v-form>

  </PageHeader>
</template>


<script>
  import fields from '@/mixins/fields';

  import PageHeader from '@/components/PageHeader';
  import Row from '@/components/Row';
  import LandSizeInput from '@/components/LandSizeInput';
  import LocationInput from '@/components/LocationInput';

  export default {

    components: { PageHeader, Row, LandSizeInput, LocationInput },

    data: () => ({
      formValid: true,
      field: undefined,
      cropRules: [ v => !!v || "Please select a crop" ],
      numberRules: [
        v => Number.isInteger(Number(v)) || 'Numbers only',
        v => (Number(v) >= 0) || 'A positive number please',
        v => !(/\D/.test(v)) || 'Numbers please',
      ],
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      images() { return this.$store.getters.images.filter( e => ( e.type_id==this.fieldUuid ) && (e.type==='field') ) ; },
      locations() { return (this.field.location ? [this.field.location] : []); },
      crops() { return this.$store.getters.crops; },
      coords() {
        return [{
          customerUuid: this.customerUuid,
          fieldUuid: this.uuid,
          caption: this.field.name,
          coordinates: this.field.map,
        }];
      },
    },

    watch: {
      customer: {
        immediate: true,
        handler(to) {
          if (to) {
            if (!to.fields) { this.$set(to, 'fields', []); }
          }
        },
      },
      fieldUuid: {
        immediate: true,
        handler(to) {
          if (to && this.customer) {
            if (to=='new') {
              this.field = this.newField();
            } else {
              this.field = this.customer.fields.filter( f => f.uuid==to )[0];
            }
            this.field.numberOfPlants = this.field.numberOfPlants || 0;
          } else {
            this.field = undefined;
          }
        },
      }
    },

    methods: {
      updateFieldSize(size) {
        this.field.fieldSize.landSize = size.landSize;
        this.field.fieldSize.unit = size.unit;
      },
      upsert() {
        this.field.name = this.deriveFieldName(this.field);
        this.updateCustomerField(this.field);
        this.$store.dispatch('upsertCustomer', this.customer);
        this.toField();
      },
      back() {
        if (this.fieldUuid==='new') {
          this.toCustomer();
        } else {
          this.toField();
        }
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      toField() { this.$router.push({name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.field.uuid }}); },
    },

    mixins: [fields],

  }
</script>


<i18n>
{
  "en": {
    "fieldName": "Field Name",
    "size": "Size",
    "plants": "Plants"
  },
  "es": {
    "fieldName": "Nombre del campo",
    "size": "Tamaño",
    "plants": "Total Plantas"
  },
  "sw": {
    "fieldName": "Jina la Shamba",
    "size": "Ukubwa",
    "plants": "Mimea"
  }
}
</i18n>
