<template>
  <PageHeader :title="customer.name"  v-if="customer" back save @back="toCustomer" @save="save" :valid='formValid' >

    <FormContainer>
      <v-row>

        <v-col cols="12" sm="4">
          <v-select ref="county" v-model="agentUuid" :items="agents" :label="$t('agents')" :hint="$t('agents')" single-line></v-select >
        </v-col>

        <v-col cols="12" sm="4">
            <v-text-field
              v-model="quantity"
              :label="$t('quantity')"
              min=0
              max=10000
              type='number'
              :rules="numberRules"
            />
        </v-col>

        <v-col cols="12" sm="4">
          <DateInput 
            v-model="createdAt" 
            :label="$t('Order Date')"
            :max="new Date().toISOString().substr(0, 10)"
            :min="new Date(Date.now() - 60*24*60*60*1000).toISOString().substr(0, 10)"
            />
        </v-col>

      </v-row>

    </FormContainer>


  </PageHeader>
</template>


<script>
  import { v4 as uuidv4 } from 'uuid';
  import validationRules from '@/mixins/validationRules';
  import uzima from '@/mixins/uzimaUg';
  import lookups from '@/mixins/lookups';

  import PageHeader from '@/components/PageHeader';
  import FormContainer from '@/components/FormContainer';
  import DateInput from '@/components/DateInput'

  export default {

    components: { PageHeader, FormContainer, DateInput },

    data: () => ({
      quantity: 0,
      createdAt: (new Date().toISOString()).substr(0, 10),
      agentUuid: undefined,
      dynamicAgents: undefined,
    }),

    computed: {
      uuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.uuid); },
      agents() {
        let result = undefined;
        if (this.dynamicAgents) {
          result = this.dynamicAgents;
        } else {
          result = this.$store.getters.uzima_ug_agents.map( e => { return { text: e.name, value: e.uuid }; });
        }
        return result;
      },
      crop() { return this.$store.getters.crops[0]; },

      location() { return ((this.$store.getters.geolocation || {}).coords); },
      fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },

      formValid() { return this.agentUuid && Number(this.quantity)>0; },
    },

    methods: {
      save() {

        const geographics = this.$store.getters.settings.fieldAgentGeographics;

        const delivery = {
          uuid: uuidv4(),
          createdAt: this.createdAt + (new Date().toISOString()).substr(10, 100),

          customerUuid: this.agentUuid,
          cropId: this.crop.value,
          quantity: parseInt(this.quantity),
          cropName: this.crop.text,
          
          country:  'TZ',
          region:   geographics[0],
          town:     geographics[1],

          unit:     'moc',

          deliveredToUuid: this.uuid,

          location: this.location,
          capturedById: this.fieldAgentId,
        }

        this.$store.dispatch('addDelivery', delivery);
        this.toCustomer();
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.uuid } }); },
      getAgent(agent) { return this.agents.filter( e => (e.value===this.agent.value) )[0]; },

      // addAgent(name) {
      //   let agent = { demographic: {} };
      //   agent.uuid = uuidv4();
      //   agent.isAFarmer = true;
      //   agent.name = name.trim();
      //   agent.fieldAgent = {
      //     id: this.$store.getters.settings.fieldAgentId,
      //     name: this.$store.getters.settings.name,
      //   };
      //   agent.demographic.regions = this.$store.getters.settings.fieldAgentGeographics;
      //   this.$store.dispatch('upsertCustomer', agent);
      //   return agent;
      // },

    },

    mounted() {
      this.remoteLookup('uzimaUgAgents').then( list => this.dynamicAgents = list );
    },

    mixins: [uzima, validationRules, lookups],

  }
</script>


<i18n>
{
  "en": {
    "agents": "Agent",
    "quantity": "Quantity Month Old Chicks"
  },
  "es": {
  },
  "sw": {
  }
}
</i18n>